import { resendConfirmEmail } from 'actions/user';
import ResendConfirmation from 'components/login/resend-confirmation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { User } from 'types/user';

const mapStateToProps = (state: {
  currentUser: {
    user: User;
  };
}) => ({
  user: state.currentUser.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resendConfirmEmail,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmation);
