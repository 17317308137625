import { forgotUserPassword } from 'actions/user';
import ForgotPassword from 'components/login/forgot-password';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state: {
  currentUser: {
    fetching: boolean;
  };
}) => ({
  fetching: state.currentUser.fetching,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgotUserPassword,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
