import { SyncOutlined } from '@ant-design/icons';
import { resendEmail } from 'actions/user';
import { withTranslation } from 'i18n';
import { TFunction, WithTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { User } from 'types/user';
import Alert from 'ui/dist/admin/alert';
import UIButton from 'ui/dist/admin/button';

interface IProps extends WithTranslation {
  t: TFunction;
  user: User;
  resendEmail: any;
}

const ResendVerificationCode: React.FC<IProps> = ({ t, user }) => {
  const [isSent, setIsSent] = useState(false);

  const handleClick = async (): Promise<void> => {
    await resendEmail({ id: user.id });
    setIsSent(true);
  };

  return (
    <div>
      {isSent ? (
        <Alert
          description={t('login:login.requestANewCodeInfo')}
          type="info"
          showIcon
        />
      ) : (
        <UIButton type="link" onClick={handleClick} className="ehq-p-0">
          <SyncOutlined className="ehq-text-black" />
          {t('login:login.requestANewCode')}
        </UIButton>
      )}
    </div>
  );
};

export default withTranslation(['login'])(ResendVerificationCode);
