import { resendEmail } from 'actions/user';
import ResendVerificationCode from 'components/login/resend-verification-code';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { User } from 'types/user';

const mapStateToProps = (state: {
  currentUser: {
    user: User;
  };
}) => ({
  user: state.currentUser.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resendEmail,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendVerificationCode);
