import 'ui/dist/admin/styles.css';

import { InfoCircleFilled } from '@ant-design/icons';
import SingleSignOn from 'containers/login/single-sign-on';
import { useTranslation, withTranslation } from 'i18n';
import encodeEmailString from 'lib/encode-email';
import { WithTranslation } from 'next-i18next';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import Alert from 'ui/dist/admin/alert';
import Button from 'ui/dist/admin/button';
import Checkbox from 'ui/dist/admin/checkbox';
import Form from 'ui/dist/admin/form';
import Input from 'ui/dist/admin/input';

interface IProps extends WithTranslation {
  primaryDomain: string;
  verifyUser: (credentials: {
    emailOrLogin: string;
    password: string;
    rememberMe: string;
  }) => Promise<string>;
  verifyAdmin: (credentials: { emailOrLogin: string }) => Promise<string>;
  signInUser: (
    credentials: {
      emailOrLogin: string;
      password: string;
    },
    isOnSiteOnBoarding: boolean,
    primaryDomainURL: string,
  ) => Promise<void>;
  fetchToken: (credentials: {
    login: string;
    password: string;
  }) => Promise<void>;
  setActiveView: (type: 'resend_confirmation') => void;
  fetchError: number;
  tokenError: number;
  tokenFetching: string;
  resetToken: () => void;
  isActiveDirectorySignupEnabled: boolean;
  isSingleSignonEnabled: boolean;
}

interface FormProps {
  form: {
    getFieldDecorator;
    validateFieldsAndScroll;
    getFieldError;
    isFieldTouched;
    getFieldsError;
    validateFields;
    resetFields;
  };
}

const SignInForm: FC<IProps & FormProps> = ({
  primaryDomain,
  verifyUser,
  signInUser,
  fetchToken,
  setActiveView,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldError,
    getFieldsError,
    isFieldTouched,
    validateFields,
    resetFields,
  },
  verifyAdmin,
  fetchError,
  tokenError,
  resetToken,
  tokenFetching,
  isActiveDirectorySignupEnabled,
  isSingleSignonEnabled,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<{
    login: string;
    password: string;
    rememberMe: '0' | '1';
  }>({
    login: '',
    password: '',
    rememberMe: '1',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [userLoginError, setUserLoginError] = useState(false);

  const passwordError = isFieldTouched('password') && getFieldError('password');
  const screeNameError = isFieldTouched('login') && getFieldError('login');

  const showSSOButton = isSingleSignonEnabled && isActiveDirectorySignupEnabled;

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const submitForm = async () => {
    await fetchToken({ login: values.login, password: values.password });
  };

  useEffect(() => {
    const credentials = {
      emailOrLogin: values.login,
      password: values.password,
      rememberMe: values.rememberMe,
    };
    if (tokenFetching === 'success') {
      signInUser(credentials, false, primaryDomain);
    }
    if (tokenError === 401) {
      setLoginError(t('login:login.platformLoginDenied'));
    }
  }, [tokenFetching, tokenError]);

  useEffect(() => {
    // clear field on page load
    resetFields();

    // To disable submit button at the beginning.
    validateFields();

    if (fetchError) {
      if (fetchError === 200) {
        submitForm();
        setIsSubmitting(false);
      } else if (fetchError === 404) {
        setLoginError(t('login:login.invalidCredentials'));
        setIsSubmitting(false);
      } else setIsSubmitting(false);
    }
  }, [fetchError]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    await Promise.all([validateFieldsAndScroll()]);
    await resetToken();
    setLoginError('');
    setIsSubmitting(true);
    const emailOrLogin = values.login;
    const password = values.password;
    const rememberMe = values.rememberMe;
    const credentials = { emailOrLogin, password, rememberMe };

    const response = await verifyUser(credentials);
    switch (response) {
      case 'LOGIN_SUCCESS': {
        // allow only admin to login
        // encode email and login
        const encodedEmail = encodeEmailString(values.login);
        await verifyAdmin({ emailOrLogin: encodedEmail });
        break;
      }
      case 'USER_NOT_CONFIRMED': {
        setIsSubmitting(false);
        setLoginError('');
        setUserLoginError(true);
        break;
      }
      case 'USER_BLOCKED': {
        setIsSubmitting(false);
        setLoginError(t('login:login.userBlocked'));
        break;
      }
      case 'LOGIN_ERROR': {
        setIsSubmitting(false);
        setLoginError(t('login:login.invalidCredentials'));
        break;
      }
      default: {
        setIsSubmitting(false);
        setLoginError(t('login:login.userNotExist'));
        break;
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const key = e.target.name;
    setValues({ ...values, [key]: e.target.value });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className="ehq-space-y-10 ehq-text-left ehq-mx-auto ehq-w-full sm:ehq-w-5/6 md:ehq-w-5/6 lg:ehq-w-8/12 xl:ehq-w-5/12 ehq-px-4"
    >
      <div className="ehq-space-y-6">
        {showSSOButton && <SingleSignOn />}
        <Form.Item
          label={t('login:login.emailOrScreenName')}
          className="ehq-font-normal label-color"
          validateStatus={screeNameError ? 'error' : ''}
          help={screeNameError || ''}
        >
          {getFieldDecorator('login', {
            rules: [
              { required: true, message: t('login:login.requiredError') },
            ],
          })(
            <Input
              type="text"
              name="login"
              className="ehq-rounded ehq-p-3 ehq-w-full"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                handleChange(e);
              }}
            />,
          )}
        </Form.Item>
        <Form.Item
          label={t('login:login.password')}
          className="ehq-font-normal label-color"
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError || ''}
        >
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: t('login:login.requiredError') },
            ],
          })(
            <Input.Password
              type="password"
              name="password"
              className="ehq-rounded ehq-w-full"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                handleChange(e);
              }}
            />,
          )}
        </Form.Item>
        <Checkbox
          value="1"
          checked={values.rememberMe == '1'}
          isChecked={values.rememberMe == '1'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setValues({
              ...values,
              rememberMe: e.target.checked ? '1' : '0',
            });
          }}
        >
          <span>{t('login:login.rememberMe')}</span>
        </Checkbox>
      </div>
      <div className="ehq-space-y-10">
        {!!loginError && (
          <Alert
            message={<div className="m-0">{loginError}</div>}
            description={<span />}
            type="error"
            showIcon
            data-testid="login-error"
          />
        )}
        {userLoginError && (
          <div
            className="ehq-bg-blue-100 ehq-items-center ehq-flex ehq-border-t-4 ehq-border-blue-500 ehq-px-4 ehq-py-3"
            role="alert"
          >
            <InfoCircleFilled className="ehq-pr-3 ehq-text-lg ehq-text-blue-500" />
            <div className="ehq-w-full">
              {t('login:login.confirmEmail')}
              <Button
                type="link"
                className="ehq-m-0 ehq-pl-0 ehq-pr-1 ehq-h-1"
                onClick={() => setActiveView('resend_confirmation')}
              >
                {t('login:login.here')}
              </Button>
              {t('login:login.sendActivationEmail')}
            </div>
          </div>
        )}
        <Form.Item>
          <Button
            disabled={isSubmitting || hasErrors(getFieldsError())}
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
          >
            {t('login:login.button.signIn')}
          </Button>
        </Form.Item>
      </div>
      <style jsx global>
        {`
          .ant-form-item-label > label {
            color: #000000;
          }
          .ant-form-item-label {
            line-height: 22px;
          }
          .ant-btn.ant-btn-primary {
            font-size: 16px;
          }
        `}
      </style>
    </Form>
  );
};

export default withTranslation(['login'])(Form.create()(SignInForm));
