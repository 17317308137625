import SingleSignOn from 'components/login/single-sign-on';
import { connect } from 'react-redux';

const mapStateToProps = (state: {
  siteSettings: {
    settings: {
      auth0ClientId: string;
      auth0Domain: string;
      auth0Connection: string;
      auth0RedirectUrl: string;
      ssoLoginPageMessage: string;
    };
  };
}) => ({
  authSettings: state.siteSettings.settings,
});

export default connect(mapStateToProps)(SingleSignOn);
