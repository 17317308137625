import { fetchToken, resetToken } from 'actions/token';
import { verifyAdmin, verifyUser } from 'actions/user';
import SignInForm from 'components/login/sign-in-form';
import { signInUser } from 'lib/sign-in-user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, ownProps) => {
  return {
    fetchError: state.currentUser.fetchError,
    tokenFetching: state.token.doneFetching,
    tokenError: state.token.fetchError,
    setActiveView: ownProps.setActiveView,
    primaryDomain:
      state.currentSite.site.primaryDomains.length > 0
        ? state.currentSite.site.primaryDomains[0]
        : null,
    isActiveDirectorySignupEnabled:
      Object.prototype.hasOwnProperty.call(
        state.features.features,
        'enable_active_directory_signup',
      ) && state.features.features.enable_active_directory_signup.enabled,
    isSingleSignonEnabled:
      Object.prototype.hasOwnProperty.call(
        state.features.features,
        'single-signon',
      ) && state.features.features['single-signon'].enabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyUser,
      verifyAdmin,
      signInUser,
      fetchToken,
      resetToken,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
