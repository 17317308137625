import { verifyAccount } from 'actions/user';
import VerifyAccount from 'components/login/verify-account';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { User } from 'types/user';

const mapStateToProps = (state: {
  currentUser: {
    user: User;
  };
}) => ({
  user: state.currentUser.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAccount,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
