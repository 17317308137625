/* global */

import { Box, Button, Heading, Text, Icon } from '@chakra-ui/react';
import { CheckCircleFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types/user';

const Success: React.FC<{ user: User; signInUser: any }> = ({
  user,
  signInUser,
}) => {
  const { t } = useTranslation();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  return (
    <Box textAlign="center">
      <Icon as={CheckCircleFilled} color="green.400" boxSize="30px" mb={4} />
      <Heading mb={4} fontSize="1.75rem">
        {t('account.success', { login: user.login })}
      </Heading>
      <Text fontSize={['.90rem', '.90rem', '1rem', '1rem']} mb={6}>
        {t('account.success_help')}
      </Text>
      <Button
        isDisabled={isLoggingIn}
        isLoading={isLoggingIn}
        colorScheme="blue"
        onClick={async (): Promise<void> => {
          setIsLoggingIn(true);

          const credentials = {
            emailOrLogin: user.email,
            password: '',
            rememberMe: '1',
          };

          signInUser(credentials);
        }}
      >
        {t('account.continue')}
      </Button>
    </Box>
  );
};

export default Success;
