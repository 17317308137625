import { fetchToken } from 'actions/token';
import { verifyUser } from 'actions/user';
import SignIn from 'components/login';
import { signInUser } from 'lib/sign-in-user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { User } from 'types/user';

const mapStateToProps = (state: {
  currentUser: {
    user: User;
    status: boolean;
  };
}) => ({
  user: state.currentUser.user,
  userStatus: state.currentUser.status,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyUser,
      signInUser,
      fetchToken,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
