import { withTranslation } from 'i18n';
import encodeEmailString from 'lib/encode-email';
import { TFunction, WithTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import Alert from 'ui/dist/admin/alert';
import Button from 'ui/dist/admin/button';
import Form from 'ui/dist/admin/form';
import Input from 'ui/dist/admin/input';

interface IProps extends WithTranslation {
  t: TFunction;
  fetching: boolean;
  forgotUserPassword: ({ email: string }) => void;
}

interface FormProps {
  form: {
    getFieldDecorator;
    validateFieldsAndScroll;
    isFieldTouched;
    getFieldError;
    getFieldsError;
    validateFields;
  };
}

const ForgotPassword: React.FC<IProps & FormProps> = ({
  t,
  forgotUserPassword,
  fetching,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldError,
    isFieldTouched,
    getFieldsError,
    validateFields,
  },
}) => {
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState('');
  const emailError = isFieldTouched('email') && getFieldError('email');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await Promise.all([validateFieldsAndScroll()]);

    const encodedEmail = encodeEmailString(email);
    await forgotUserPassword({ email: encodedEmail });
    setIsSent(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  useEffect(() => {
    // To disable submit button at the beginning.
    validateFields();
  }, []);

  return (
    <div>
      {isSent ? (
        <div className="ehq-text-left ehq-mx-auto ehq-w-full md:ehq-w-5/6 lg:ehq-w-5/12 ehq-space-y-10">
          <Alert
            message={t('login:login.resetPasswordMessage')}
            description={t('login:login.resetPasswordDescription')}
            type="info"
            showIcon
          />
        </div>
      ) : (
        <Form
          onSubmit={handleSubmit}
          className="ehq-text-left ehq-mx-auto ehq-w-full md:ehq-w-5/6 lg:ehq-w-5/12 ehq-space-y-10"
        >
          <Form.Item
            label={t('login:login.email')}
            className="ehq-font-normal label-color ehq-m-0"
            validateStatus={emailError ? 'error' : ''}
            help={emailError || ''}
          >
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: t('login:login.requiredError') },
                { type: 'email', message: t('login:login.invalid') },
              ],
            })(
              <Input
                name="email"
                className="ehq-rounded ehq-p-3 ehq-w-full"
                onChange={handleChange}
              />,
            )}
          </Form.Item>
          <Button
            disabled={fetching || hasErrors(getFieldsError())}
            loading={fetching}
            htmlType="submit"
            block
            type="primary"
          >
            {t('login:login.submit')}
          </Button>
        </Form>
      )}
    </div>
  );
};

export default withTranslation(['login'])(Form.create()(ForgotPassword));
