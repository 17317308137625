const encodeEmailString = (email) => {
  /**
   * Step 1: Check if the URL has a plus sign
   * Step 2: Split the email by '@' (There can only be one @ symbol). This is needed because we don't need to touch the domain.
   * Step 3: Construct the URL after encoding the plus sign
   */
  const hasPlusSign = email.indexOf('+') > -1
  const emailPartition = email?.split('@')
  const isValidEmail = /\S+@\S+\.\S+/.test(email)

  if (isValidEmail) {
    if (email && hasPlusSign && emailPartition && emailPartition.length === 2) {
      const emailWithoutDomain = emailPartition[0]
      const encodedEmail = `${emailWithoutDomain.replaceAll('+', '%2B')}@${
        emailPartition[1]
      }`

      return encodedEmail
    }
  } else {
    if (email && hasPlusSign) {
      const encodedEmail = `${email.replaceAll('+', '%2B')}`

      return encodedEmail
    }
  }

  return email
}

export default encodeEmailString
