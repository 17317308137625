/* Auth0 */
import React, { FC, useState } from 'react';
import Button from 'ui/dist/admin/button';

type Props = {
  authSettings: {
    auth0ClientId: string;
    auth0Domain: string;
    auth0Connection: string;
    auth0RedirectUrl: string;
    ssoLoginPageMessage: string;
  };
};

const SingleSignOn: FC<Props> = ({ authSettings }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const loadScript = (src: string, callback: () => void): void => {
    const script = document.createElement('script');
    script.onload = (): void => {
      callback();
    };
    script.setAttribute('src', src);
    document.body.appendChild(script);
  };

  const handleClick = (): void => {
    setIsDisabled(true);
    // Taken from https://github.com/bangthetable/ehqv2/blob/master/app/assets/javascripts/auth0_adfs.js
    loadScript('https://cdn.auth0.com/w2/auth0-6.8.min.js', () => {
      // @ts-ignore
      new Auth0({
        domain: authSettings.auth0Domain,
        clientID: authSettings.auth0ClientId,
        callbackURL:
          authSettings.auth0RedirectUrl + `?referer=${window.location.href}`,
      }).signin({
        connection: authSettings.auth0Connection,
        scope: 'openid email profile',
      });
    });
  };
  return (
    <Button
      disabled={isDisabled}
      type="primary"
      block
      onClick={handleClick}
      loading={isDisabled}
    >
      {authSettings.ssoLoginPageMessage}
    </Button>
  );
};

export default SingleSignOn;
