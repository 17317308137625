import ResendVerificationCode from 'containers/login/resend-verification-code';
import { withTranslation } from 'i18n';
import { TFunction, WithTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { User } from 'types/user';
import UIButton from 'ui/dist/admin/button';
import UIForm from 'ui/dist/admin/form';
import Input from 'ui/dist/admin/input';

const OTP_LENGTH = 4;

interface IProps extends WithTranslation {
  t: TFunction;
  user: User;
  setActiveView: any;
  verifyAccount: any;
}

const VerifyAccount: React.FC<IProps> = ({
  t,
  user,
  setActiveView,
  verifyAccount,
}) => {
  const [otp, setOtp] = useState('');
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!touched) {
      setTouched(true);
    }

    // if value is present continue
    if (otp.trim()) {
      await verifyAccount({ id: user.id, otp });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <UIForm className="ehq-mb-5">
        <UIForm.Item
          className="ehq-mb-1"
          required
          rules={[
            {
              required: true,
              message: "can't be blank",
            },
          ]}
          hasFeedback
          validateStatus={touched || errors.length > 0 ? 'error' : ''}
          help={touched || errors.length > 0 ? "can't be blank" : ''}
          label={t('login:login.verification_code')}
        >
          <Input
            type="digit"
            id={`${user.login}_otp`}
            value={otp}
            required
            minLength={OTP_LENGTH}
            maxLength={OTP_LENGTH}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setOtp(e.target.value);
            }}
            onBlur={(): void => {
              setTouched(true);
            }}
          />
        </UIForm.Item>
        <UIButton
          isdisabled={isSubmitting}
          isloading={isSubmitting}
          className="ehq-rounded ehq-mt-3 ehq-px-4 ehq-text-white ehq-bg-blue-500 ehq-hover:ehq-bg-black ehq-cursor-pointer"
          type="submit"
          onClick={handleSubmit}
        >
          {t('login:login.confirm_account')}
        </UIButton>
      </UIForm>
      <ResendVerificationCode />
    </>
  );
};

export default withTranslation(['login'])(VerifyAccount);
