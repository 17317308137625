import Container from 'containers/login';
import withAdminOnboardingPageLayout from 'hocs/with-admin-on-boarding-page-layout';
import withAdminStore from 'hocs/with-admin-store';
import withI18n from 'hocs/with-i18n';
import withParticipantAccess from 'hocs/with-participant-access';
import React from 'react';

const App = () => {
  return <Container />;
};

const locales = ['common', 'login', 'site-on-board'];
const PageWithLayoutAndI18n = withI18n(
  withAdminOnboardingPageLayout(App),
  locales,
);
const PageWithParticipantAccess = withParticipantAccess(PageWithLayoutAndI18n);
const PageWithRedux = withAdminStore(PageWithParticipantAccess);

export default PageWithRedux;
