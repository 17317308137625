import { withTranslation } from 'i18n';
import encodeEmailString from 'lib/encode-email';
import { TFunction, WithTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { User } from 'types/user';
import UIForm from 'ui/dist/admin/form';
import Input from 'ui/dist/admin/input';

interface IProps extends WithTranslation {
  t: TFunction;
  user: User;
  setActiveView: any;
  resendConfirmEmail: any;
}

const ResendConfirmation: React.FC<IProps> = ({
  t,
  setActiveView,
  user,
  resendConfirmEmail,
}) => {
  const [email, setEmail] = useState(user.email);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    const encodedEmail = encodeEmailString(email);

    await resendConfirmEmail({ id: encodedEmail });
    setActiveView('verify_account');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <UIForm.Item className="ehq-mb-1" required>
        <label htmlFor="ResetPasswordEmail">
          {t('login:login.email')}
          <span className="ehq-pl-1 ehq-text-red-500">*</span>
        </label>
        <Input
          required
          type="email"
          id="ResetPasswordEmail"
          value={email}
          onChange={handleChange}
        />
      </UIForm.Item>
      <button
        className="ehq-rounded ehq-mt-3 ehq-px-4 ehq-py-2 ehq-border-0 ehq-text-white ehq-bg-blue-500 ehq-hover:ehq-bg-black ehq-cursor-pointer"
        type="submit"
      >
        {t('login:login.submit')}
      </button>
    </form>
  );
};

export default withTranslation(['login'])(ResendConfirmation);
